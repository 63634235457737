import classNames from 'classnames'
import React from 'react'
import sc from '../../../classes.scss'
import st from '../title/title.scss'
import s from './title-location.scss'

interface TitleProps {
  event: wix.events.Event
}

export const TitleLocation = ({event}: TitleProps) => (
  <div className={classNames(s.container)}>
    <div
      className={classNames(s.title, sc.textLineHeight, st.listHeaderTitleFont, st.listHeaderTitleColor)}
      data-hook="ev-list-item-title"
    >
      {event.title}
    </div>
    <div
      className={classNames(st.listHeaderTitleFont, st.listHeaderTitleColor, s.locationVisibility)}
      aria-hidden="true"
    >
      &nbsp;/&nbsp;
    </div>
    <div
      className={classNames(s.listHeaderLocation, s.location, s.locationVisibility)}
      data-hook="ev-list-item-location"
    >
      {event.location.name || event.location.address}
    </div>
  </div>
)
