import classNames from 'classnames'
import React from 'react'
import {useEventDateInformation} from '../../../../../../../commons/hooks/dates'
import {useVisibilityStyles} from '../../../../hooks/use-visibility-styles'
import sc from '../../../classes.scss'
import s from './date.scss'
import {DateProps} from '.'

export const Date = ({customColorClass = '', scheduleTbd, event}: DateProps) => {
  const {
    allBreakpoints: {isListDateEnabled},
  } = useVisibilityStyles()
  const {shortStartDate} = useEventDateInformation(event.id)

  if (!isListDateEnabled()) {
    return null
  }

  return (
    <div
      className={classNames(
        s.root,
        sc.textNoMargin,
        s.listHeaderDateTimeFont,
        customColorClass || s.listHeaderDateTimeColor,
      )}
      data-hook={scheduleTbd ? 'ev-date-tbd' : 'ev-date'}
    >
      {shortStartDate}
    </div>
  )
}
