import classNames from 'classnames'
import React from 'react'
import {ExpandArrow} from '../../../../../../../icons/ExpandArrow'
import sc from '../../../classes.scss'
import s from './title.scss'
import {TitleProps} from '.'

export const Title = ({active, event, opened, withOverflow}: TitleProps) => {
  const classes = classNames(sc.textLineHeight, s.listHeaderTitleFont, s.listHeaderTitleColor, {
    [s.listContentTitleColor]: active,
    [s.overflow]: withOverflow,
  })

  return (
    <span className={classes} role="button" aria-expanded={opened}>
      <span className={s.title} data-hook="ev-list-item-title">
        {event.title}
      </span>
      <span className={classNames(s.expandArrow, {[s.reversed]: opened})}>
        <ExpandArrow />
      </span>
    </span>
  )
}
